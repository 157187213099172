import breakpoints from './breakpoints';
import useMediaQuery from '@mui/material/useMediaQuery';

const UseMatches = (): { desktopMatches: boolean } => {
  const desktopMatches = useMediaQuery('@media (min-width:' + breakpoints.values.lg + 'px)');

  return {
    desktopMatches,
  };
};

export { UseMatches };
