import type { NextPage } from 'next';
import React from 'react';
import { AccountLayout } from '@components/Account/AccountLayout';
import { UseStyles } from 'hooks';
import { PasswordForm, EmailForm, PhoneForm, SocialProviders, CreateCredentialsForm } from 'views/account/login';
import $account from 'services/Account';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';

const Profile: NextPage = () => {
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const classes = UseStyles();
  const showCreateCredentialsForm =
    $account.roleAdmin() && data?.providers.length === 0 && !data.email && data.household_member;
  return (
    <AccountLayout title='Login & Security'>
      <div className={classes.accountLoginWrapper}>
        {!showCreateCredentialsForm && (
          <>
            <PhoneForm />
            <EmailForm />
            <PasswordForm />
            <SocialProviders />
          </>
        )}
        {showCreateCredentialsForm && <CreateCredentialsForm />}
      </div>
    </AccountLayout>
  );
};

export default Profile;
