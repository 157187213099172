import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { EditField } from '@ui/molecules/EditField/EditField';

export interface ComponentProps {
  type: string;
  value: string;
  handleSave: (
    value: string,
    actions: {
      setIsSaving: Dispatch<SetStateAction<boolean>>;
      setEditState: Dispatch<SetStateAction<boolean>>;
    }
  ) => void;
  view?: (value: string) => string;
  onEditStateChange?: (value: boolean) => void;
}

export function ChangeInput({ type, value, view, handleSave, onEditStateChange }: ComponentProps): JSX.Element {
  const [isEditState, setEditState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    !!value && setNewValue(value);
  }, [value]);
  return (
    <EditField
      handleEditClick={(e) => {
        e.preventDefault();
        setEditState(true);
        !!onEditStateChange && onEditStateChange(true);
      }}
      saving={isSaving}
      readOnly={!isEditState}
      value={view ? view(value) : value}
      viewBtnLabel={value ? 'Edit' : 'Add'}
      onChange={({ target }) => setNewValue(target.value)}
      type={type}
      updatedValue={newValue}
      onSave={() => {
        handleSave(newValue, { setIsSaving, setEditState });
      }}
      onClose={(e) => {
        e.preventDefault();
        setEditState(false);
        !!onEditStateChange && onEditStateChange(false);
      }}
      verified={!!value}
    />
  );
}
