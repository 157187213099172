import { AxiosResponse } from 'axios';
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { UseStyles } from 'hooks';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import Button from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { ChangeInput } from './ChangeInput';
import { useMemberApi } from 'hooks/useMemberApi';
import MemberApi from 'services/api/member';
import { MemberAuthPhoneSendCodeOutput, MemberInfoOutput } from 'services/api/client';
import { Container } from 'services';
import { Kustomer } from 'containers/Widget/Kustomer';
import { useStoreon } from 'storeon/react';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';

type HandleSave = (
  value: string,
  actions: {
    setIsSaving: Dispatch<SetStateAction<boolean>>;
    setEditState: Dispatch<SetStateAction<boolean>>;
  }
) => void;

const errorMessages = {
  'data.required': 'The phone is required.',
  'data.constraint.string.phone_number:valid': 'The phone is invalid.',
  'data.constraint.string.phone_number.user:unique': 'The phone already exists.',
  'identity.phone.INVALID_CODE': 'The code is invalid.',
  'identity.phone.INVALID_PHONE_NUMBER': 'The phone is invalid.',
  'identity.phone.TOO_MANY_ATTEMPTS_TRY_LATER': 'Too many phone change attempts, please try later.',
};

export function PhoneForm(): JSX.Element {
  const classes = UseStyles();
  const [smsCode, setSmsCode] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const { dispatch } = useStoreon();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isPhonePopupOpen, togglePhonePopup] = useState(false);
  const [isPhoneEditState, setIsPhoneEditState] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [captchaSessionToken, setCaptchaSessionToken] = useState('');
  const { data, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');
  const handleSave: HandleSave = async (phone, { setEditState, setIsSaving }) => {
    if (phone === data?.phone) {
      return;
    }
    try {
      setIsSaving(true);
      setErrorMessage(null);
      const ret = (await MemberApi.memberAuthPhoneSendCode({
        phone,
        recaptcha_token: recaptchaToken,
      })) as unknown as MemberAuthPhoneSendCodeOutput;
      setCaptchaSessionToken(ret.session_info!);
      setNewPhone(phone);
      setEditState(false);
      togglePhonePopup(true);
    } catch (err) {
      const error = err as AxiosResponse<HttpResponseError>;
      if (!error?.data?.error) {
        throw err;
      }
      const { type, details: { code, constraint } = {}, message } = error.data.error;
      const errorId = [type, code, constraint].filter((i) => i).join('.') as keyof typeof errorMessages;
      setErrorMessage(errorMessages[errorId] ?? message);
    }
    setIsSaving(false);
  };
  const submitSmsCode = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      setIsSaving(true);
      setErrorMessage(null);
      await MemberApi.memberAuthPhoneValidateCode({
        code: smsCode,
        session_info: captchaSessionToken,
      });
      mutate({ ...data, phone: newPhone });
      togglePhonePopup(false);
      dispatch('message/open', 'The phone has been changed.');
    } catch (err) {
      const error = err as AxiosResponse<HttpResponseError>;
      if (!error?.data?.error) {
        throw err;
      }
      const { type, details: { code, constraint } = {}, message } = error.data.error;
      const errorId = [type, code, constraint].filter((i) => i).join('.') as keyof typeof errorMessages;
      setErrorMessage(errorMessages[errorId] ?? message);
    }
    setIsSaving(false);
  };
  useEffect(() => {
    if (isPhoneEditState) {
      const appshell = Container.get('appshell');
      const { RecaptchaVerifier } = appshell.auth;
      new RecaptchaVerifier('send-code-button', {
        size: 'invisible',
        callback: async (recaptchaToken: string) => {
          setRecaptchaToken(recaptchaToken);
        },
      }).verify();
    }
  }, [isPhoneEditState]);
  return (
    <>
      <style>{`
        .grecaptcha-badge {
          visibility: hidden;
        }
      `}</style>
      <FieldContainer
        label='Phone Number'
        helperTextBottom='By giving your mobile number, you are opting in to get SMS account-related alerts. Carrier costs (e.g.
          Messaging & Data Rates) may apply. In order to change number you must verify your number. Change number?'
        error={errorMessage ? new Error(errorMessage) : undefined}
      >
        <>
          <ChangeInput
            type='textfield'
            value={data?.phone ?? ''}
            view={(value: string) => value.replace(/^\+?([0-9]{1})([0-9]{3})([0-9]{3})([0-9]+)$/, '+$1 ($2) $3 $4')}
            handleSave={handleSave}
            onEditStateChange={(value: boolean) => setIsPhoneEditState(value)}
          />
        </>
      </FieldContainer>
      <DialogDefault open={isPhonePopupOpen} onClose={() => togglePhonePopup(false)} title='Enter your code'>
        <div className={classes.accountDialogLoginCode}>
          <div className={classes.accountDialogLoginCodeTitle}>
            To confirm your new phone number, we will send 6-digit security code from your phone. Click the button below
            to send the code.
          </div>
          <div className={classes.accountDialogLoginCodeField}>
            <label className={classes.accountFieldLabel}>Security Code</label>
            <MuiTextField
              onChange={(e) => setSmsCode(e.target.value)}
              fullWidth
              name='code'
              variant='outlined'
              placeholder='Enter security code'
            />
          </div>
        </div>
        <div className={`${classes.accountDialogActions} ${classes.accountDialogActionsRow} dialog_actions`}>
          <Button disabled={isSaving} color='primary' variant='contained' onClick={submitSmsCode}>
            Submit
            {isSaving && <CircularProgress size={24} />}
          </Button>
        </div>
        {errorMessage ? <div className={classes.accountDialogLoginCodeError}>{errorMessage}</div> : null}
        <div className={classes.accountDialogLoginCodeTrouble}>
          <Kustomer buttonText='Having trouble logging in?' />
        </div>
      </DialogDefault>
    </>
  );
}
