import React, { BaseSyntheticEvent, useState } from 'react';
import { AxiosResponse } from 'axios';
import IconButton from '@mui/material/IconButton';
import { HttpClient } from 'services';
import { useStoreon } from 'storeon/react';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import Account from 'services/Account';
import { useForm, FormProvider } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from '@components/Form';
import { debounce } from 'debounce';
import { PageTitle } from '@ui/atoms/PageTitle/PageTitle';
import FieldError from '@ui/atoms/FieldError/FieldError';
import { EditFieldAction } from '@ui/molecules/EditFieldAction/EditFieldAction';
import { UseMatches } from '@theme/mediaQuery';

type Password = {
  renewed?: string;
  repeated?: string;
};

export function CreateCredentialsForm(): JSX.Element {
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [password, setPassword] = useState<Password>({});
  const [email, setEmail] = useState<string>();
  const [passwordType, setPasswordType] = useState<Password>({
    renewed: 'password',
    repeated: 'password',
  });
  const { data, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');
  const methods = useForm();
  const { handleSubmit } = methods;
  const togglePasswordType = (type: keyof Password) => {
    setPasswordType((passwordType) => ({
      ...passwordType,
      [type]: passwordType[type] === 'password' ? 'text' : 'password',
    }));
  };
  const { dispatch } = useStoreon();
  const { desktopMatches } = UseMatches();
  const handleChange = (type: keyof Password) => (e: BaseSyntheticEvent) =>
    setPassword((password) => ({ ...password, [type]: e.target.value }));
  const handleEmailChange = () => (e: BaseSyntheticEvent) => setEmail(e.target.value);
  const onSubmit = (data: Data) => {
    setIsSaving(true);
    setErrorMessage(null);
    HttpClient.post('/member/auth/email/set', {
      email,
      password: data.renewed,
    })
      .then(() => {
        mutate();
        dispatch('message/open', 'Credentials for the user are created.');
        setIsSaving(false);
      })
      .catch((err) => {
        const error = err as AxiosResponse<HttpResponseError>;
        if (!error?.data?.error) {
          throw err;
        }
        setErrorMessage(error.data.error.message);
        setIsSaving(false);
      });
  };
  const { renewed, repeated } = password;
  const passwordSet = !!data?.providers.includes('password');
  const submitDisabled = !email || (passwordSet && !Account.roleAdmin()) || !renewed || renewed !== repeated;

  return (
    <FormProvider {...methods}>
      <PageTitle size='small'>Create new login credentials for a household member.</PageTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          label='Enter Account Email'
          name='current'
          type='email'
          onChange={debounce(handleEmailChange(), 250)}
        />
        <TextField
          fullWidth
          label='Enter Password'
          name='renewed'
          type={passwordType.renewed}
          onChange={debounce(handleChange('renewed'), 250)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle renewed password visibility'
                  onClick={() => {
                    togglePasswordType('renewed');
                  }}
                >
                  {passwordType.renewed === 'password' ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label='Re-enter Password'
          name='repeated'
          type={passwordType.repeated}
          onChange={debounce(handleChange('repeated'), 250)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle repeated password visibility'
                  onClick={() => {
                    togglePasswordType('repeated');
                  }}
                >
                  {passwordType.repeated === 'password' ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errorMessage ? <FieldError size={'medium'}>{errorMessage}</FieldError> : null}
        <EditFieldAction
          label='Create Credentials'
          saving={isSaving}
          disabled={isSaving || submitDisabled}
          size={desktopMatches ? 'small' : 'medium'}
        />
      </form>
    </FormProvider>
  );
}
