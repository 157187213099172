import React, { useState, useEffect } from 'react';
import { UseStyles } from 'hooks';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import Button from '@mui/material/Button';
import { useMemberApi } from '../../../hooks/useMemberApi';
import { MemberInfoOutput } from '../../../services/api/client';
import Cookies from '../../../services/Cookies';
import memberApi from 'services/api/member';
import { ensureUrl, hostTld } from 'utils';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';
import FieldSocialProvider from '@ui/molecules/FieldSocialProvider/FieldSocialProvider';

export function SocialProviders(): JSX.Element {
  const classes = UseStyles();

  const [selectedProvider, setSelectedProvider] = useState('google.com');
  const [socialDialogOpen, setSocialDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isSaving, setIsSaving] = useState(false);

  const { data, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');
  const googleProvider = data?.providers.includes('google.com');
  const facebookProvider = data?.providers.includes('facebook.com');

  const masqueraded = !!Cookies.get('masquerade') || !!data?.masqueraded;

  useEffect(() => {
    window.addEventListener('message', async (event) => {
      if (!event.origin.endsWith(`.${hostTld()}`) && !event.origin.endsWith(`.${hostTld()}:3001`)) {
        return;
      }
      // Reload page.
      if (event?.data?.provider) {
        mutate();
        setIsSaving(false);
      }
    });
  }, []);

  /**
   * Open window popup.
   *
   * @param url
   * @param windowName
   * @param win
   * @param w
   * @param h
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const popupWindow = (url: string, windowName: string, win: any, w: number, h: number) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    const popup = win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        setIsSaving(false);
      }
    }, 1000);
  };

  /**
   * Connect social provider.
   *
   * @param type
   */
  const connectSocialAuthProvider = (type: string) => {
    const urlEnding = type === 'google.com' ? 'google' : 'facebook';
    const domain = ensureUrl(process.env.NEXT_PUBLIC_AUTH_APP_URL);
    const url = `${domain}/manage/${urlEnding}`;
    popupWindow(url, 'va-social-popup', window, 375, window.innerHeight * 0.9);
    setIsSaving(true);
  };

  /**
   * Disconnect social provider action.
   */
  const disconnectSocialProvider = async () => {
    if (!selectedProvider) {
      return;
    }
    setErrorMessage(null);

    if (masqueraded) {
      await memberApi.memberAuthProviderUnlink({ provider_id: selectedProvider });
      mutate();
    } else {
      const urlEnding = selectedProvider === 'google.com' ? 'google' : 'facebook';
      const domain = ensureUrl(process.env.NEXT_PUBLIC_AUTH_APP_URL);
      const url = `${domain}/manage/${urlEnding}/disconnect`;
      popupWindow(url, 'va-social-popup', window, 375, window.innerHeight * 0.9);
    }
    setSocialDialogOpen(false);
    setIsSaving(true);
  };

  return (
    <>
      <FieldContainer label='Social authentification'>
        <FieldSocialProvider
          type='facebook.com'
          masqueraded={masqueraded}
          action={facebookProvider ? 'disconnect' : 'connect'}
          errorMessage={errorMessage ?? ''}
          isSaving={isSaving}
          connectSocialAuthProvider={connectSocialAuthProvider}
          setSocialDialogOpen={setSocialDialogOpen}
          setSelectedProvider={setSelectedProvider}
        />
        <FieldSocialProvider
          type='google.com'
          masqueraded={masqueraded}
          action={googleProvider ? 'disconnect' : 'connect'}
          errorMessage={errorMessage ?? ''}
          isSaving={isSaving}
          connectSocialAuthProvider={connectSocialAuthProvider}
          setSocialDialogOpen={setSocialDialogOpen}
          setSelectedProvider={setSelectedProvider}
        />
      </FieldContainer>
      <DialogDefault open={socialDialogOpen} onClose={() => setSocialDialogOpen(false)} title='Disconnect Account'>
        <div className={classes.accountDialogLoginSocialDisconnect}>
          <div className={classes.accountDialogLoginSocialDisconnectTitle}>
            Are you sure you want to disconnect account?
          </div>
        </div>
        <div className={`${classes.accountDialogActions} ${classes.accountDialogActionsRow} dialog_actions`}>
          <Button color='primary' variant='contained' onClick={() => setSocialDialogOpen(false)}>
            Keep Account
          </Button>
          <Button color='primary' variant='outlined' onClick={() => disconnectSocialProvider()}>
            Disconnect
          </Button>
        </div>
      </DialogDefault>
    </>
  );
}

export default SocialProviders;
