import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import styles from './EditFieldAction.module.scss';
import { EditFieldActionProps } from './EditFieldAction.interface';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';
import cn from 'classnames';
import NextLink from 'next/link';

export function EditFieldAction({
  saving,
  label,
  disabled,
  onClick,
  onClose,
  link,
  size = 'small',
}: EditFieldActionProps): JSX.Element {
  return (
    <FieldContainer>
      <div className={styles.accountBaseInputActions}>
        {!link && (
          <Button
            color='primary'
            disabled={disabled}
            type={onClick ? 'button' : 'submit'}
            variant='outlined'
            size={size}
            onClick={onClick}
            className={cn({ [styles.ActionsMobileFullWidth]: !onClose })}
          >
            {label}
            {saving ? <CircularProgress size={20} className={styles.accountBaseInputViewProgress} /> : null}
          </Button>
        )}
        {link && (
          <NextLink href={link} passHref>
            <Button
              color='primary'
              variant='outlined'
              size={size}
              className={cn({ [styles.ActionsMobileFullWidth]: !onClose })}
            >
              {label}
            </Button>
          </NextLink>
        )}
        {onClose && (
          <IconButton className={styles.accountBaseInputActionsClose} onClick={onClose}>
            <CloseIcon className='icon' />
          </IconButton>
        )}
      </div>
    </FieldContainer>
  );
}
