import React from 'react';
import { CheckCircleIcon } from 'theme/icons';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import styles from './ViewField.module.scss';
import { EditFieldProps } from './ViewField.interface';

export function ViewField({
  verified = true,
  value,
  viewBtnLabel,
  handleEditClick,
  saving,
}: EditFieldProps): JSX.Element {
  return (
    <div className={styles.accountBaseInputView}>
      {verified && <CheckCircleIcon className={`${styles.icon} icon`} />}
      <div className={styles.accountBaseInputViewValue}>{value}</div>
      {viewBtnLabel && (
        <Button className={styles.accountBaseInputViewBtn} onClick={handleEditClick}>
          {viewBtnLabel}
        </Button>
      )}
      {saving && <CircularProgress size={20} className={styles.accountBaseInputViewProgress} />}
    </div>
  );
}
