import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import styles from './EditField.module.scss';
import { EditFieldProps } from './EditField.interface';
import { ViewField } from '../ViewField/ViewField';

export function EditField({
  readOnly = true,
  verified = true,
  value,
  updatedValue,
  viewBtnLabel,
  handleEditClick,
  saving,
  onChange,
  onClose,
  type = 'text',
  onSave,
}: EditFieldProps): JSX.Element {
  if (readOnly) {
    return (
      <ViewField
        value={value}
        handleEditClick={handleEditClick}
        viewBtnLabel={viewBtnLabel}
        saving={saving}
        verified={verified}
      />
    );
  } else {
    return (
      <div className={styles.accountBaseInputEdit}>
        <TextField
          fullWidth
          variant='outlined'
          className={styles.accountBaseInputEditField}
          onChange={onChange}
          type={type}
          value={updatedValue}
        />
        <div className={styles.accountBaseInputEditButtons}>
          {onSave && (
            <Button id='send-code-button' className={styles.accountBaseInputEditBtn} onClick={(e) => onSave(e)}>
              Save
            </Button>
          )}
          {saving && <CircularProgress size={20} className={styles.accountBaseInputViewProgress} />}
          {onClose && (
            <IconButton className={styles.accountBaseInputEditClose} onClick={onClose}>
              <CloseIcon className={`${styles.icon} icon`} />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}
