import { AxiosResponse } from 'axios';
import React, { useState, Dispatch, SetStateAction, useCallback } from 'react';
import { UseStyles } from 'hooks';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import Button from '@mui/material/Button';
import { ChangeInput } from './ChangeInput';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import memberApi from 'services/api/member';
import adminApi from 'services/api/admin';
import $account from 'services/Account';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';

type HandleSave = (
  value: string,
  actions: {
    setIsSaving: Dispatch<SetStateAction<boolean>>;
    setEditState: Dispatch<SetStateAction<boolean>>;
  }
) => void;

const errorMessages = {
  'data.required': 'The email is required.',
  'data.constraint.string.email:valid': 'The email is invalid.',
  'data.constraint.string.email.user:domain_valid': 'The email domain is invalid.',
  'data.constraint.string.email.user:unique': 'The email already exists.',
  password_provider: 'Password should be set first in order to change the email.',
};

export function EmailForm(): JSX.Element {
  const classes = UseStyles();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isEmailPopupOpen, toggleEmailPopup] = useState(false);
  const { data, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');
  const handleSave: HandleSave = useCallback(
    async (email, { setEditState, setIsSaving }) => {
      if (email === data?.email) {
        return;
      }
      try {
        setIsSaving(true);
        setErrorMessage(null);
        if (data?.masqueraded && $account.roleAdmin()) {
          await adminApi.memberAuthEmailSet({ id: data?.id, email });
          await mutate((cachedData: MemberInfoOutput) => ({
            ...cachedData,
            email,
          }));
          setEditState(false);
          return;
        }

        await memberApi.memberAuthEmailUpdate({ email });
        setEditState(false);
        toggleEmailPopup(true);
      } catch (err) {
        const error = err as AxiosResponse<HttpResponseError>;
        if (!error?.data?.error) {
          throw err;
        }
        const { type, details: { constraint } = {}, message } = error.data.error;
        const errorId = [type, constraint].filter((i) => i).join('.') as keyof typeof errorMessages;
        setErrorMessage(errorMessages[errorId] ?? message);
      } finally {
        setIsSaving(false);
      }
    },
    [data?.email, mutate]
  );
  return (
    <>
      <FieldContainer label='Email Address' error={errorMessage ? new Error(errorMessage) : undefined}>
        <ChangeInput type='email' value={data?.email ?? ''} handleSave={handleSave} />
      </FieldContainer>
      <DialogDefault open={isEmailPopupOpen} onClose={() => toggleEmailPopup(false)} title='Email Verification'>
        <div className={classes.accountDialogLoginEmailVerification}>
          <div className={classes.accountDialogLoginEmailVerificationTitle}>Please confirm your email address.</div>
          <div>To complete email change please verify your email</div>
        </div>
        <div className={`${classes.accountDialogActions} ${classes.accountDialogActionsRow} dialog_actions`}>
          <Button color='primary' variant='outlined' size='small' onClick={() => toggleEmailPopup(false)}>
            Close
          </Button>
        </div>
      </DialogDefault>
    </>
  );
}
