import React, { BaseSyntheticEvent, useState } from 'react';
import { AxiosResponse } from 'axios';
import IconButton from '@mui/material/IconButton';
import { HttpClient } from 'services';
import { useStoreon } from 'storeon/react';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import Account from 'services/Account';
import { useForm, FormProvider } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from '@components/Form';
import { debounce } from 'debounce';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';
import { ViewField } from '@ui/molecules/ViewField/ViewField';
import FieldError from '@ui/atoms/FieldError/FieldError';
import { EditFieldAction } from '@ui/molecules/EditFieldAction/EditFieldAction';

type Password = {
  current?: string;
  renewed?: string;
  repeated?: string;
};

const errorMessages = {
  'data.constraint.string:length>=8': {
    field: 'renewed',
    message: 'The password should have at least 8 characters.',
  },
  'data.constraint.object.action.password:least_one_letter': {
    field: 'renewed',
    message: 'The password should have at least one character.',
  },
  'identity.password.INVALID_PASSWORD': {
    field: 'current',
    message: 'The password is incorrect.',
  },
};

export function PasswordForm(): JSX.Element {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditState, setEditState] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [password, setPassword] = useState<Password>({});
  const [passwordType, setPasswordType] = useState<Password>({
    current: 'password',
    renewed: 'password',
    repeated: 'password',
  });
  const { data, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');
  const methods = useForm();
  const { handleSubmit, setError } = methods;
  const togglePasswordType = (type: keyof Password) => {
    setPasswordType((passwordType) => ({
      ...passwordType,
      [type]: passwordType[type] === 'password' ? 'text' : 'password',
    }));
  };
  const { dispatch } = useStoreon();
  const handleChange = (type: keyof Password) => (e: BaseSyntheticEvent) =>
    setPassword((password) => ({ ...password, [type]: e.target.value }));
  const onSubmit = async (data: Data) => {
    try {
      setIsSaving(true);
      setErrorMessage(null);
      await HttpClient.post('/member/auth/password/update', {
        current: data.current,
        renewed: data.renewed,
      });
      setEditState(false);
      mutate();
      dispatch('message/open', 'The password has been changed.');
    } catch (err) {
      const error = err as AxiosResponse<HttpResponseError>;
      if (!error?.data?.error) {
        throw err;
      }
      const { type, details: { code, constraint } = {}, message } = error.data.error;
      const errorId = [type, code, constraint].filter((i) => i).join('.') as keyof typeof errorMessages;
      if (errorMessages[errorId]) {
        const { field, message } = errorMessages[errorId];
        setError(field, {
          type: 'manual',
          message,
        });
      } else {
        setErrorMessage(message);
      }
    }
    setIsSaving(false);
  };
  const { current, renewed, repeated } = password;
  const passwordSet = !!data?.providers.includes('password');
  const submitDisabled = (passwordSet && !current && !Account.roleAdmin()) || !renewed || renewed !== repeated;
  if (!isEditState) {
    return (
      <FieldContainer label='Current Password'>
        <ViewField
          value={passwordSet ? '••••••••••' : 'N/A'}
          verified={false}
          viewBtnLabel={passwordSet ? 'Change password' : 'Add password'}
          handleEditClick={() => setEditState(true)}
        />
      </FieldContainer>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {passwordSet && !Account.roleAdmin() && (
            <TextField
              fullWidth
              label='Current Password'
              name='current'
              type={passwordType.current}
              onChange={debounce(handleChange('current'), 250)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle current password visibility'
                      onClick={() => {
                        togglePasswordType('current');
                      }}
                    >
                      {passwordType.current === 'password' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <TextField
            fullWidth
            label='New Password'
            name='renewed'
            type={passwordType.renewed}
            onChange={debounce(handleChange('renewed'), 250)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle renewed password visibility'
                    onClick={() => {
                      togglePasswordType('renewed');
                    }}
                  >
                    {passwordType.renewed === 'password' ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label='Re-enter new Password'
            name='repeated'
            type={passwordType.repeated}
            onChange={debounce(handleChange('repeated'), 250)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle repeated password visibility'
                    onClick={() => {
                      togglePasswordType('repeated');
                    }}
                  >
                    {passwordType.repeated === 'password' ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorMessage && <FieldError>{errorMessage}</FieldError>}
          <EditFieldAction
            disabled={isSaving || submitDisabled}
            saving={isSaving}
            onClose={() => setEditState(false)}
            label='Update password'
          />
        </form>
      </FormProvider>
    );
  }
}
