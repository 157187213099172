import React from 'react';
import Button from '@mui/material/Button';
import styles from './FieldSocialProvider.module.scss';
import { SocialProviderActionProps } from './FieldSocialProvider.interface';
import { UseMatches } from '@theme/mediaQuery';

function FieldSocialProvider({
  action,
  errorMessage,
  isSaving,
  type,
  masqueraded,
  connectSocialAuthProvider,
  setSocialDialogOpen,
  setSelectedProvider,
}: SocialProviderActionProps): JSX.Element {
  const providerTitle = type === 'google.com' ? 'Google' : 'Facebook';
  const currentStatus = action === 'connect' ? 'disconnected' : 'connected';
  const actionTitle = action === 'disconnect' ? 'Disconnect' : 'Connect';
  const hideForMasqueraded = masqueraded && action === 'connect';
  const { desktopMatches } = UseMatches();

  const processAction = () => {
    setSelectedProvider(type);
    action === 'disconnect' ? setSocialDialogOpen(true) : connectSocialAuthProvider(type);
  };

  return (
    <>
      {errorMessage && <div className={styles.accountDialogLoginCodeError}>{errorMessage}</div>}
      {!hideForMasqueraded && (
        <div className={styles.accountLoginSocialItem}>
          <div className={styles.accountLoginSocialItemContent}>
            <img
              className={styles.accountLoginSocialItemLogo}
              src={type === 'google.com' ? `/images/account/google-logo.svg` : `/images/account/facebook-logo.svg`}
              alt={providerTitle}
            />
            <div className={styles.accountLoginSocialItemInfo}>
              <div className={styles.accountLoginSocialItemName}>
                {providerTitle} {currentStatus}
              </div>
              <div className={styles.accountLoginSocialItemNote}>We’ll never post on your behalf.</div>
            </div>
          </div>
          <Button
            className={styles.accountLoginSocialItemBtn}
            size={desktopMatches ? 'small' : 'medium'}
            disabled={isSaving}
            color='primary'
            variant='outlined'
            onClick={processAction}
          >
            {actionTitle}
          </Button>
        </div>
      )}
    </>
  );
}

export default FieldSocialProvider;
